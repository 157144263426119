<template>
  <div class="container-xxl py-24">
    <MetaTags
      :title="title"
      :description="description"
    />
    <AppBreadcrumbs
      :links="[
        {
          text: 'Кабинет',
          alias: '/applicant/responses'
        },
        {
          text: 'Видеорезюме'
        }
      ]"
    />
    <div
      ref="refPageTitle"
      class="d-md-flex justify-content-md-between align-items-md-center mb-15"
      style="justify-content: space-between !important;"
    >
      <h1 class="heading-header-1 d-inline-block mb-md-0">
        Видеорезюме
      </h1>
    </div>
    <AppSteps
      class="mb-24"
      :active-step="activeTab"
      :steps="[
        {
          title: 'Информация о себе',
          description: 'Заполните немного полей. Следующий шаг будет опыт работы.',
          isValid: isAboutMeValid,
        },
        {
          title: 'Об опыте',
          description: 'Заполните немного полей. Следующий шаг будет запись интервью.',
          isValid: isExperienceValid,
        },
        {
          title: 'Запись вопросов',
          description: 'Запишите короткие ответы на вопросы. Следующий шаг будет фото.',
          isValid: isRecordsValid,
        },
        {
          title: 'Фото',
          description: 'Сфотографируй себя. Следующий шаг будет публикация.',
          isValid: isPhotoValid,
        },
        {
          title: 'Опубликовать',
          description: 'Проверьте информацию и видео. Нажмите опубликовать.',
          isValid: false,
        }
      ]"
      @change-step="step => changeTab(step)"
      @next-step="nextTab"
    />

    <div
      class="mx-auto"
      style="max-width: 1000px;"
    >
      <div class="row">
        <div
          ref="summaryBlock"
          class="col-12"
        >
          <WindowItem :class="{ 'd-none': activeTab !== 0 }">
            <div class="row">
              <AppInput
                v-model.trim="$v.formName.$model"
                class="col-12 col-sm-6 mb-24"
                name="name"
                label="Имя"
                :is-invalid="$v.formName.$error"
                error-message="Имя не может быть пусты и должно состоять только из символов кириллице"
              />
              <AppInput
                v-model.trim="$v.formSurname.$model"
                class="col-12 col-sm-6 mb-24"
                name="surname"
                label="Фамилия"
                :is-invalid="$v.formSurname.$error"
                error-message="Фамилия не может быть пусты и должно состоять только из символов кириллице"
              />
            </div>

            <div class="mb-24">
              <label
                for="experience"
                class="form-label fw-bold"
              >
                Дата рождения
              </label>
              <DatetimePicker
                v-model="formDateBirth"
                format="dd.MM.yyyy"
                :class="$v.formDateBirth.$error ? 'is-invalid' : ''"
                :input-class="$v.formDateBirth.$error ? 'is-invalid' : ''"
                :enable-time-picker="false"
                :min-date="undefined"
              />
              <div class="invalid-feedback">
                {{ $v.formDateBirth.age.$invalid ? 'Возраст пользователя должен быть не меньше 14 лет' : 'Введите дату рождения' }}
              </div>
            </div>
            <div class="mb-24">
              <label class="form-label fw-bold">Дополнительная связь</label>
              <span class="d-block text-accent fs-14">Проверьте актуальность!</span>
              <div
                v-for="(item) in socials"
                class="mt-10"
              >
                <div class="d-flex align-items-center">
                  <svg
                    height="25"
                    width="25"
                    class="me-5"
                  >
                    <use :xlink:href="`/public/icons/${item.icon}.svg#${item.icon}`" />
                  </svg>
                  <span class="fs-14 me-5">{{ item.name }}</span>
                  <svg
                    v-show="formSocial[item.key] === undefined"
                    height="30"
                    width="30"
                    type="button"
                    class="text-primary"
                    @click="formSocial[item.key] = client.phone"
                  >
                    <use xlink:href="/public/icons/auth-sprite.svg#add" />
                  </svg>
                </div>
                <div
                  class="d-flex align-items-center"
                  :class="{'d-none' : formSocial[item.key] === undefined}"
                >
                  <AppInput
                    v-model="$v.formSocial[item.key].$model"
                    name="phone"
                    type="tel"
                    class="w-100 me-15"
                    maska="+7 (F##) ###-##-##"
                    :maska-tokens="{
                      F: { pattern: '[012345679]' },
                    }"
                    error-message="Номер телефона не корректен"
                    :is-invalid="$v.formSocial[item.key].$error"
                  />
                  <svg
                    height="40"
                    width="40"
                    class="mt-10"
                    type="button"
                    @click="delete formSocial[item.key]"
                  >
                    <use xlink:href="/public/icons/main.svg#close" />
                  </svg>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-around">
              <AppButton
                class="invisible"
                :variant="BtnVariant.Tertiary"
                @click="changeTab(activeTab - 1)"
              >
                <template #prepend-icon>
                  <svg class="w-100 h-100 flip-horizontal">
                    <use xlink:href="/public/icons/main.svg#east" />
                  </svg>
                </template>
                Назад
              </AppButton>
              <AppButton
                @click="nextTab"
              >
                Далее
                <template #append-icon>
                  <svg class="w-100 h-100">
                    <use xlink:href="/public/icons/main.svg#east" />
                  </svg>
                </template>
              </AppButton>
            </div>
          </WindowItem>
          <WindowItem :class="{ 'd-none': activeTab !== 1 }">
            <div class="mb-24">
              <label
                for="professions"
                class="form-label"
              >
                <span class="fw-bold">Профессия</span> <small>выбрать не более трёх</small>
              </label>
              <SelectLayout
                v-model="formProfessions"
                class="form-control"
                :class="$v.formProfessions.$error ? 'is-invalid' : ''"
                :selectable="() => formProfessions.length < maxProfessions"
                :url-getter="(val) => '/professions?title=' + val"
                target="professions"
                multiple
              />
            </div>

            <div class="row mb-24">
              <div class="col-12 col-sm-6 mb-20 mb-sm-0">
                <span class="d-block form-label fw-bold">Опыт работы в профессии</span>
                <SelectLayout
                  v-model="$v.formExperienceProf.$model"
                  class="form-control"
                  :class="{ 'is-invalid': $v.formExperienceProf.$error }"
                  :options="ExperienceList"
                  :searchable="false"
                  :clearable="false"
                />
                <div class="invalid-feedback">
                  Выберите опыт работы
                </div>
              </div>
              <label class="col-12 col-sm-6">
                <span class="d-block form-label fw-bold">Общий стаж, год.</span>
                <input
                  id="experience"
                  v-model="$v.formExperience.$model"
                  class="form-control"
                  :class="$v.formExperience.$error ? 'is-invalid' : ''"
                  type="number"
                  min="0"
                >
              </label>
            </div>
            <div class="mb-24">
              <span class="d-block fw-bold form-label">Желаемый график работы</span>
              <div class="d-flex flex-wrap flex-md-row flex-column w-lg-50 w-md-60 w-100">
                <div
                  v-for="schedule in schedules"
                  class="col-md-6 col-12 d-block checkbox-type type-1"
                >
                  <label class="checkbox-type-label">
                    <input
                      v-model="formSchedules"
                      class="checkbox-type-input"
                      type="checkbox"
                      :value="schedule.type"
                    >
                    <div class="checkbox-type-icon">
                      <svg
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        data-inject-url="https://peterdraw.studio/ziro-html/assets/icons/check-circle-stroke.svg"
                        class="svg-inject"
                      >
                        <path
                          class="svg-circle"
                          d="M19.0397 6.72224C18.2235 4.99229 16.9309 3.53961 15.3025 2.52038C13.0376 1.10675 10.3548 0.657663 7.754 1.25904C5.15323 1.85652 2.93905 3.43417 1.52541 5.69911C0.107872 7.96405 -0.341211 10.6429 0.26017 13.2476C0.861551 15.8484 2.4392 18.0626 4.70024 19.4762C6.29351 20.472 8.12108 20.9992 9.99161 20.9992H10.1127C11.9754 20.9758 13.7873 20.4369 15.3572 19.445C15.8141 19.156 15.9468 18.5546 15.6579 18.0977C15.3689 17.6408 14.7675 17.5081 14.3106 17.797C13.0493 18.5976 11.5888 19.031 10.0892 19.0506C8.55064 19.0701 7.04719 18.6444 5.73508 17.8283C3.91141 16.688 2.64226 14.9073 2.16194 12.8142C1.68162 10.721 2.04088 8.56543 3.18116 6.74177C5.53202 2.98118 10.5071 1.83309 14.2677 4.18394C15.5798 5.00401 16.6185 6.17163 17.2746 7.56183C17.915 8.91689 18.1649 10.4203 17.997 11.9043C17.9384 12.4393 18.3211 12.9235 18.86 12.9821C19.395 13.0406 19.8793 12.658 19.9378 12.1191C20.1448 10.272 19.8324 8.40533 19.0397 6.72224V6.72224Z"
                        />
                        <path
                          class="svg-check"
                          d="M13.229 7.6088L8.51947 12.3183L6.77391 10.5727C6.39121 10.19 5.77421 10.19 5.39151 10.5727C5.00882 10.9554 5.00882 11.5724 5.39151 11.9551L7.82828 14.3919C8.01963 14.5832 8.26955 14.677 8.51947 14.677C8.7694 14.677 9.01932 14.5832 9.21067 14.3919L14.6075 8.99119C14.9902 8.6085 14.9902 7.9915 14.6075 7.6088C14.2248 7.23001 13.6078 7.23001 13.229 7.6088Z"
                        />
                      </svg>
                    </div>
                    <div class="checkbox-type-text">
                      {{ schedule.name }}
                    </div>
                  </label>
                </div>
              </div>
              <div
                class="invalid-feedback"
                :class="$v.formSchedules.$error ? 'd-block' : ''"
              >
                Выберите хотя бы один пункт
              </div>
            </div>
            <div class="d-flex justify-content-around">
              <AppButton
                :variant="BtnVariant.Tertiary"
                @click="changeTab(activeTab - 1)"
              >
                <template #prepend-icon>
                  <svg class="w-100 h-100 flip-horizontal">
                    <use xlink:href="/public/icons/main.svg#east" />
                  </svg>
                </template>
                Назад
              </AppButton>
              <AppButton
                @click="nextTab"
              >
                Далее
                <template #append-icon>
                  <svg class="w-100 h-100">
                    <use xlink:href="/public/icons/main.svg#east" />
                  </svg>
                </template>
              </AppButton>
            </div>
          </WindowItem>
          <VideoRecord
            :class="{ 'd-none': activeTab !== 2 && activeTab !== 3 }"
            :questions="questions"
            :is-active="activeTab === 2"
            :active-slot="activeTab === 4"
            :is-update="isUpdate"
            :is-show="activeTab > 1"
            :stream="stream"
            :is-camera-denied="isCameraDenied"
            :is-photo="activeTab === 3"
            :photo-src-old="photoSrc"
            @get-photo="getPhoto"
            @set-record-blob="setRecordBlob"
            @webm-supported="setWebmSupported"
            @change-question="changeQuestion"
          >
            <template #nextStep>
              <AppButton
                class="fw-bold mx-auto d-flex"
                @click="nextTab"
              >
                Далее
              </AppButton>
            </template>
          </VideoRecord>
          <WindowItem :class="{ 'd-none': activeTab !== 4 }">
            <div class="mb-20">
              <p class="fw-bold mb-10">
                Видимость
              </p>
              <div class="d-flex align-items-center">
                <SelectLayout
                  v-model="formVisibility"
                  class="form-control"
                  style="width: 225px;"
                  :reduce="(val) => val.id"
                  :options="[
                    {
                      id: 2,
                      title: 'Видно всем',
                    },
                    {
                      id: 3,
                      title: 'Видно по ссылке',
                    },
                    {
                      id: 1,
                      title: 'Скрыто',
                    },
                  ]"
                  :clearable="false"
                />
                <AppButton
                  class="ms-15"
                  title="Скопировать ссылку на видеорезюме"
                  is-icon
                  :class="isUpdate && formVisibility === 3 ? 'visible' : 'invisible'"
                  :variant="BtnVariant.Tertiary"
                  @click="copyLink"
                >
                  <svg class="h-100 w-100">
                    <use xlink:href="/public/icons/main.svg#link" />
                  </svg>
                </AppButton>
              </div>
            </div>
            <div class="mb-20">
              <p class="fw-bold mb-10">
                Выбранные профессии
              </p>
              <div class="d-flex flex-wrap gap-10">
                {{ getProfessionList }}
              </div>
            </div>
            <div class="mb-20">
              <p class="fw-bold mb-10">
                Опыт работы в профессии
              </p>
              {{ formExperienceProf !== undefined ? formExperienceProf.title : null }}
            </div>
            <div class="mb-20">
              <p class="fw-bold mb-10">
                Общий стаж, год
              </p>
              {{ typeof formExperience !== 'number' || formExperience === 0 ? 'Без опыта' : formExperience + ' ' + useDeclination(formExperience, 'год', 'года','лет') }}
            </div>
            <div class="mb-20">
              <p class="fw-bold mb-10">
                Дата рождения
              </p>
              {{ formDateBirth?.toLocaleString(['ru-RU', 'en-US'], {day: '2-digit', month: 'short', year: 'numeric'}) }}
            </div>
            <div class="mb-20">
              <p class="fw-bold mb-10">
                График работы
              </p>
              <div class="d-flex flex-wrap gap-10">
                {{ getScheduleList }}
              </div>
            </div>
            <div class="mb-20">
              <p class="fw-bold mb-10">
                Запись вопросов
              </p>
              <AppButton
                :variant="BtnVariant.Tertiary"
                @click="itemInfoModalShow = true"
              >
                Смотреть
              </AppButton>
            </div>
            <AppButton
              class="d-flex mx-auto fw-bold"
              :disabled="sending"
              @click="saveAll"
            >
              Опубликовать
            </AppButton>
          </WindowItem>
        </div>
      </div>
    </div>

    <VueFinalModal
      v-model="itemInfoModalShow"
      display-directive="show"
      :content-class="isMobile ? '' : 'modal-dialog modal-dialog-centered justify-content-center'"
    >
      <div
        class="modal-content"
        :class="{
          'rounded-20 overflow-hidden': !isMobile
        }"
        :style="{
          height: wrapHeight + 'px',
          width: wrapWidth + 'px',
        }"
      >
        <VideoPlayer
          :arrow-show="true"
          :only-step="true"
          is-hidden-timer
          is-reflection
          :answers="getVideoData"
          :wrap-height="wrapHeight"
          :wrap-width="wrapWidth"
          @close="itemInfoModalShow = false"
        />
      </div>
    </VueFinalModal>
  </div>
</template>

<script setup lang="ts">
import type { Profession } from "~/entities/profession";
import SelectLayout from "~/components/inputs/selectLayout.vue";
import { useApiSummaries } from "~/composables/api/summaries";
import { useClient } from "~/stores/client";
import { useVuelidate } from "@vuelidate/core";
import { required, integer, maxLength, minLength, minValue, and } from "@vuelidate/validators";
import {cyrillic, phone} from "~/composables/validator";
import DatetimePicker from "~/components/inputs/datetimePicker.vue";
import type { QuestionRecord } from "~/entities/QuestionRecord";
import { useApiRecordUpload } from "~/composables/api/RecordUpload";
import { useClipboard } from "@vueuse/core";
import { BtnVariant } from "~/composables/enums/BtnVariant";
import { ExperienceList } from "~/entities/Experience";
import { useDeclination } from "~/composables/utils/Declination";
import type { UploadVideoData } from "~/entities/UploadVideoData";
import AppInput from "~/components/inputs/AppInput.vue";
import AppSteps from "~/components/steps/AppSteps.vue";
import {VueFinalModal} from "vue-final-modal";
import type {VideoData} from "~/entities/videoData";
import {useCheckMobile} from "~/composables/CheckMobile";
import { useVideoSettings } from "~/composables/videoSettings";
import type {SummaryDraftRequestDto} from "~/entities/Summary/SummaryDraftRequestDto";

const config = useRuntimeConfig();
const { $toast } = useNuxtApp();
const summariesApi = useApiSummaries();
const router = useRouter();
const route = useRoute();
const client = useClient();
const apiRecordUpload = useApiRecordUpload();
const { copy, copied } = useClipboard({ source: `${config.public.protocol}://${config.public.domain}/summaries/${client.summaryId}` });

//-----TYPES-----\\
type Social = {
  telegram: string | undefined,
  whatsapp: string | undefined,
  viber: string | undefined,
};

//-----VARIABLES-----\\
const maxProfessions = 3;
const schedules = [
  {
    type: 1,
    name: 'Полный рабочий день',
  },
  {
    type: 2,
    name: 'Сменный график',
  },
  {
    type: 3,
    name: 'Свободный график',
  },
  {
    type: 4,
    name: 'Частичная занятость',
  },
  {
    type: 5,
    name: 'Удаленная работа',
  },
  {
    type: 6,
    name: 'Вахта',
  },
];
const socials = [
  {
    name: 'Whatsapp',
    key: 'whatsapp',
    icon: 'whatsapp'
  },
  {
    name: 'Telegram',
    key: 'telegram',
    icon: 'telegram-blue'
  },
  {
    name: 'Viber',
    key: 'viber',
    icon: 'viber'
  }
];
let isWebmSupported = true;
let isDraft: boolean = true;
let photoIsLoaded: boolean = false;
let activeQuestion = 0;

//-----STATE-----\\
const summaryBlock = ref();
const refPageTitle = ref();
const formVisibility = ref(2);
const formName = ref<string>();
const formSurname = ref<string>();
const formProfessions = ref<Profession[]>([]);
const formDateBirth = ref<Date|null>(null);
const formExperienceProf = ref();
const formExperience = ref<number>(0);
const formSchedules = ref<number[]>([1]);
const formSocial = ref<Social|null>({
  telegram: client.phone,
  whatsapp: client.phone,
  viber: client.phone,
});
const questions = ref<QuestionRecord[]>([]);
const activeTab = ref<number>(route.query.isRewrite !== undefined ? 2 : 0);
const sending = ref<boolean>(false);
const isUpdate = ref<boolean>(false);
const title = ref<string>('');
const description = ref<string>('');
const uploadVideoList = useState<UploadVideoData[]>('uploadVideoList');
const itemInfoModalShow = ref<boolean>(false);
const isMobile = ref<boolean>(false);
const wrapHeight = ref<number>();
const wrapWidth = ref<number>();
const stream = ref<MediaStream>();
const isCameraDenied = ref<boolean>(true);
const photoBlob = ref<Blob|null>(null);
const photoSrc = ref<string|null>(null);
const isLoading = ref<boolean>(false);

//-----COMPUTED-----\\
const $v = useVuelidate({
  formName: { and: and(required, cyrillic) },
  formSurname: { required, cyrillic },
  formProfessions: { required, minLength: minLength(1), maxLength: maxLength(maxProfessions) },
  formDateBirth: { required, age: (val:any) => {
      if (val !== null) {
        const today = new Date();
        let age;
        if (today.toLocaleString(['ru-RU', 'en-US'], {day: '2-digit'}) === val.toLocaleString(['ru-RU', 'en-US'], {day: '2-digit'})
          && today.toLocaleString(['ru-RU', 'en-US'], {month: 'short'}) === val.toLocaleString(['ru-RU', 'en-US'], {month: 'short'}))
        {
          age = today.getFullYear() - val.getFullYear();
        } else {
          let diffYear =(today.getTime() - val.getTime()) / 1000;
          diffYear /= (60 * 60 * 24);
          age = Math.abs(diffYear/365.25);
        }
        return age >= 14;
      }
      return true;
    }
  },
  formExperienceProf: { required },
  formExperience: { required, integer, minValue: minValue(0) },
  formSchedules: { required, minLength: minLength(1) },
  formSocial: {
    telegram: { phone },
    whatsapp: { phone },
    viber: { phone }
  },
}, {
  formName, formSurname, formProfessions, formDateBirth, formExperienceProf, formExperience, formSchedules, formSocial
});
const isAboutMeValid = computed<boolean>(() => {
  let tmp = false;
  socials.map((item) => {
    if ($v.value.formSocial[item.key].$invalid) {
      tmp = true;
      return;
    }
  });

  return !($v.value.formName.$invalid || $v.value.formSurname.$invalid || $v.value.formDateBirth.$invalid || tmp);
});
const isExperienceValid = computed<boolean>(() => {
  return !(
    $v.value.formProfessions.$invalid || $v.value.formExperience.$invalid ||
    $v.value.formExperienceProf.$invalid || $v.value.formSchedules.$invalid
  );
});
const isPhotoValid = computed<boolean>(() => {
  return photoSrc.value !== null;
});

const isRecordsValid = computed<boolean>(() => {
  for (let i = 0; i < questions.value.length; i++) {
    if (questions.value[i].recordBlob === null && questions.value[i].answer === null) {
      return false;
    }
  }

  return true
});
const getProfessionList = computed<string>(() => {
  if (formProfessions.value.length === 0) {
    return '';
  }

  let response = '';
  for (let i = 0; i < formProfessions.value.length; i++) {
    response += (i === 0 ? '' : ', ') + formProfessions.value[i].title;
  }

  return response;
});
const getScheduleList = computed<string>(() => {
  if (formSchedules.value.length === 0) {
    return '';
  }

  let response = '';
  for (let i = 0; i < formSchedules.value.length; i++) {
    response += (i === 0 ? '' : ', ') + schedules.find(val => val.type === formSchedules.value[i]).name;
  }

  return response;
});
const getVideoData = computed((): VideoData[] => {
  if (questions.value.length === 0) {
    return [];
  }

  const videos: VideoData[] = [];
  for (let i = 0; i < questions.value.length; i++) {
    const item = questions.value[i];
    if (item.recordBlob === null && item.answer === null) {
      continue;
    }

    videos.push({
      src: item.recordBlob === null && item.answer !== null ? item.answer!.src : URL.createObjectURL(item.recordBlob),
      question: item.question,
      duration: 0,
      isAssembled: item.recordBlob === null ? true : (item.answer !== null ? item.answer!.isAssembled : true),
      isBlob: item.recordBlob !== null,
    });
  }

  return videos;
});

//-----FETCH-----\\
if (activeTab.value === 2) {
  activateCamera();
}

if (client.isSummaryCreated) {
  const { data } = await summariesApi.getOne(client.summaryId!);
  const response = data.value;
  formName.value = response.summary.client.name;
  formSurname.value = response.summary.client.surname;
  formProfessions.value = response.summary.professions;
  formDateBirth.value = new Date(response.summary.dateBirth * 1000);
  formExperienceProf.value = response.summary.experienceInProfession;
  formExperience.value = response.summary.experience;
  formSchedules.value = response.summary.schedules.map(val => val.type);
  photoSrc.value = response.summary.client.photo;
  photoIsLoaded = true;
  response.summary.questions.map(val => {
    questions.value.push({
      id: val.id,
      question: val.question,
      hint: val.hint,
      position: val.position,
      type: val.type,
      time: val.time,
      isRequired: val.isRequired,
      recordId: null,
      answer: val.answer,
      recordBlob: null,
      isLoaded: true,
    } as QuestionRecord);
  });
  formVisibility.value = response.summary.visibility;
  formSocial.value = response.summary.social === null || Object.keys(response.summary.social).length === 0 ? {} : response.summary.social;
  isUpdate.value = true;

  isDraft = response.summary.isDraft;

  title.value = `${response.summary.client.name} ${response.summary.client.surname} редактировать видеорезюме`;
  description.value = `Редактировать видеорезюме ${response.summary.client.name} ${response.summary.client.surname}`;
} else {
  const { data } = await mainFetch('questions');
  questions.value = [];
  if (data.value.questions !== undefined) {
    for (const i in data.value.questions) {
      const question = data.value.questions[i];
      questions.value.push({
        id: question.id,
        question: question.question,
        hint: question.hint,
        position: question.position,
        type: question.type,
        time: question.time,
        isRequired: question.isRequired,
        answer: null,
        recordBlob: null,
      } as QuestionRecord);
    }
  }
}

//-----METHODS-----\\
function getPhoto(params: any) {
  photoIsLoaded = false;

  photoBlob.value = params.photoBlob;
  photoSrc.value = params.photoSrc;
}
function copyLink() {
  copy();
  if (copied) {
    $toast.success('Ссылка на вакансию скопирована');
  }
}
function nextTab() {
  changeTab(activeTab.value + 1);
}
function restartCamera() {
  $toast.warning('Camera down');

  stream.value?.getTracks().forEach(track => {
    track.stop();
  });

  activateCamera();
}
async function activateCamera() {
  try {
    stream.value = await navigator.mediaDevices.getUserMedia(useVideoSettings(!isMobile));
    stream.value.getTracks().forEach(track => {
      // track.addEventListener("ended", restartCamera);
      track.addEventListener("ended", e => console.error('track ended', e));
    });

    isCameraDenied.value = false;
  } catch (err) {
    $toast.error('Не удалось получить доступ к видеокамере и/или микрофону');
    isCameraDenied.value = true;
    return;
  }
}
function changeTab(tab: number) {
  if (isLoading.value) {
    return;
  }

  if (tab > activeTab.value) {
    if (activeTab.value === 0 && !isAboutMeValid.value) {
      socials.map((item) => {
        $v.value.formSocial[item.key].$touch();
      });
      $v.value.formName.$touch();
      $v.value.formSurname.$touch();
      $v.value.formDateBirth.$touch();

      return;
    } else if (activeTab.value === 1 && !isExperienceValid.value) {
      $v.value.formProfessions.$touch();
      $v.value.formExperience.$touch();
      $v.value.formExperienceProf.$touch();
      $v.value.formSchedules.$touch();

      return;
    } else if (activeTab.value === 2 && !isRecordsValid.value) {
      return;
    } else if (activeTab.value === 3 && !isPhotoValid.value) {
      return;
    }
  }

  if (activeTab.value === 0) {
    socials.map((item) => {
      if (formSocial.value[item.key] === '') {
        delete formSocial.value[item.key];
      }
    });
  } else if (activeTab.value === 1) {
    saveDraft();
  } else if (activeTab.value === 2) {
    saveVideo(questions.value[activeQuestion]);
  } else if (activeTab.value === 3) {
    savePhoto();
  }

  if (isMobile.value) {
    const inputs = summaryBlock.value.getElementsByTagName('input');
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].blur();
    }
  }

  window.scrollTo(0, refPageTitle.value.getBoundingClientRect().top + window.scrollY);

  activeTab.value = tab;
  if ((tab === 2 || tab === 3) && isCameraDenied.value) {
    activateCamera();
  }
}
async function publication() {
  $v.value.$touch();
  if ($v.value.$invalid) {
    changeTab(0);
    return;
  }

  if (questions.value.findIndex(value => value.isRequired === true && value.recordBlob === null && value.answer === null) !== -1) {
    changeTab(1);
    return;
  }

  /*const records = await Promise.all(questions.value.filter(item => item.answer !== null || item.recordBlob !== null).map(async (item) => {
    return {
      id: item.answer?.id??null,
      question: item.id,
      hash: item.recordBlob === null ? null : await apiRecordUpload.getHash(item.recordBlob)
    };
  }));*/
  const records = [];
  for (let i = 0; i < questions.value.length; i++) {
    const item = questions.value[i];
    if (item.answer === null && item.recordBlob === null) {
      continue;
    }

    records.push({
      id: item.answer?.id??null,
      question: item.id,
      hash: item.recordBlob === null ? null : await apiRecordUpload.getHash(item.recordBlob)
    });
  }

  const month = formDateBirth.value!.getMonth() + 1;
  const day = formDateBirth.value!.getDate();
  const body = {
    name: formName.value,
    surname: formSurname.value,
    professions: formProfessions.value.map(val => val.id),
    dateBirth: formDateBirth.value!.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day),
    experienceInProfession: formExperienceProf.value.id,
    experience: formExperience.value,
    schedules: formSchedules.value,
    records,
    visibility: formVisibility.value,
    social: formSocial.value
  };

  const newVideos = questions.value.filter(value => value.recordBlob !== null);
  const loadStepCount = newVideos.length + 1;

  let apiResponse;
  let isError = false;
  sending.value = true;
  if (isUpdate.value) {
    await summariesApi.update(client.summaryId!, body).then(response => {
      response = response.data.value??response.error.value;
      if (response.error.code !== 200) {
        isError = true;
        return;
      }
      apiResponse = response;
    });
  } else {
    await summariesApi.add(body).then(response => {
      response = response.data.value??response.error.value;
      if (response.error.code !== 201) {
        isError = true;
        return;
      }

      client.summaryId = response.summary.id;
      client.isSummaryCreated = true;
      apiResponse = response;
    });
  }

  if (photoBlob.value !== null) {
    const formData = new FormData();
    formData.append('photo', photoBlob.value!);
    await mainFetch(`/clients/set-photo`, {
      method: 'POST',
      body: formData
    }).then(response => {
      response = response.data.value??response.error.value?.data;
      if (response.error.code !== 200) {
        isError = true;
        return;
      }

      client.photo = response.photo;
    });
  }

  if (isError) {
    $toast.error('ERROR');
    sending.value = false;
    return;
  }

  client.name = body.name!;
  client.surname = body.surname!;

  if (loadStepCount !== 1) {
    (apiResponse.records as object[]).forEach(value => {
      for (let i = 0; i < newVideos.length; i++) {
        if (value.questionId !== newVideos[i].id) {
          continue;
        }

        newVideos[i].answer = {
          id: value.answerId,
          src: null,
          isAssembled: false,
          created: Math.ceil((new Date()).getTime() / 1000),
        };
        break;
      }
    });

    const errVideoCount = newVideos.filter(value => value.answer === null).length;

    if (errVideoCount !== 0) {
      $toast.error(`${errVideoCount}/${newVideos.length} видео не могут быть сохранены`);
    }

    if (errVideoCount !== newVideos.length) {
      for (let i = 0; i < newVideos.length; i++) {
        if (newVideos[0].answer === null) {
          continue;
        }

        uploadVideoList.value.push({
          token: apiResponse.recordToken,
          answerId: newVideos[i].answer!.id,
          isVacancy: false,
          file: newVideos[i].recordBlob!,
          isWebm: isWebmSupported,
          upload: {
            status: 0,
            size: {
              loaded: null,
              total: null,
            },
          },
        });
      }
    }
  }

  sending.value = false;
  $toast.success(loadStepCount === 1 ? 'Успешно' : 'Успешно. Осталось лишь дождаться окончания загрузки видео.');

  router.push('/applicant/responses');
}
function setRecordBlob(index: number, value: Blob|null) {
  questions.value[index].recordBlob = value;
  questions.value[index].answer = null;
  questions.value[index].isLoaded = false;
}
function setWebmSupported(val: boolean) {
  isWebmSupported = val;
}

function changeQuestion(old: number, next: number) {
  activeQuestion = next;

  saveVideo(questions.value[old]);
}
function getDataForDraft(): SummaryDraftRequestDto {
  const month = formDateBirth.value!.getMonth() + 1;
  const day = formDateBirth.value!.getDate();

  return {
    name: formName.value,
    surname: formSurname.value,
    professions: formProfessions.value.map(val => val.id),
    dateBirth: formDateBirth.value!.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day),
    experienceInProfession: formExperienceProf.value.id,
    experience: formExperience.value,
    schedules: formSchedules.value,
    social: formSocial.value
  } as SummaryDraftRequestDto;
}
async function saveDraft() {
  if (isLoading.value || isUpdate.value) {
    return;
  }

  isLoading.value = true;

  const response = await summariesApi.add(getDataForDraft());
  isLoading.value = false;
  if (response.error.value !== null || response.data.value.error.code !== 201) {
    $toast.error('Возникла ошибка при создании черновика');
    console.error('Error in creating a job draft', response);

    return;
  }

  client.summaryId = response.data.value.summary.id;
  client.isSummaryCreated = true;
  isUpdate.value = true;

  $toast.info('Черновик видеорезюме создан');
}
async function saveAll() {
  if (!isUpdate.value || isLoading.value) {
    return;
  }

  isLoading.value = true;
  //TODO:

  const response = await summariesApi.update(client.summaryId!, {
    ...getDataForDraft(),
    visibility: formVisibility.value,
    status: isDraft ? 2 : undefined,
  });
  isLoading.value = false;
  if (response.error.value !== null || response.data.value.error.code !== 200) {
    $toast.error('Возникла ошибка при сохранении, попробуйте повторить позже');
    console.error('Error in save vacancy', response);

    return;
  }

  isDraft = false;

  router.push('/applicant/responses');
}
async function saveVideo(video: QuestionRecord) {
  if (video.isLoaded) {
    return;
  }

  let hash = null;
  if (video.recordBlob !== null) {
    if (video.recordBlob?.size === 0) {
      $toast.error('Файл записанного видео поврежден');
      console.error('Blob size == 0');

      return;
    }

    hash = await apiRecordUpload.getHash(video.recordBlob);
  }

  const response = await summariesApi.update(client.summaryId!, {
    records: [
      {
        id: video.answer?.id??null,
        question: video.id,
        hash: hash
      }
    ],
  });
  if (response.error.value !== null || response.data.value.error.code !== 200) {
    $toast.error('При сохранении видео возникла непредвиденная ошибка');
    console.error('SaveVideo get token error', response);

    return;
  }

  video.isLoaded = true;

  if (hash === null) {
    return;
  }

  uploadVideoList.value.push({
    token: response.data.value.recordToken,
    answerId: response.data.value.records[0].answerId,
    isVacancy: true,
    file: video.recordBlob!,
    isFlip: video.isFlip,
    isWebm: isWebmSupported,
    upload: {
      status: 0,
      size: {
        loaded: null,
        total: null,
      },
    },
  });
}
async function savePhoto() {
  if (isLoading.value || photoIsLoaded) {
    return;
  }

  isLoading.value = true;

  const formData = new FormData();
  formData.append('photo', photoBlob.value!);

  const response = await mainFetch(`clients/set-photo`, {
    method: 'POST',
    body: formData
  });
  isLoading.value = false;
  if (response.error.value !== null || response.data.value.error.code !== 200) {
    $toast.error('При сохранении фотографии возникла непредвиденная ошибка');
    console.error('Save photo error', response);

    return;
  }

  photoIsLoaded = true;
  client.photo = response.data.value.photo;
}

onMounted(() => {
  isMobile.value = useCheckMobile();

  if (isMobile.value) {
    wrapHeight.value = window.innerHeight;
    wrapWidth.value = window.innerWidth;
  } else {
    wrapHeight.value = window.innerHeight * 0.8;
    wrapWidth.value = (720 / 1280) * wrapHeight.value;
  }
});
</script>

<style lang="scss">
</style>
